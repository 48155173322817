import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './utils/PrivateRoute'
import { AuthProvider } from './context/AuthContext'
import './scss/style.scss'
import ProtectedPage from './views/ProtectedPage'
import 'react-toastify/dist/ReactToastify.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'))
const Reset = React.lazy(() => import('./views/pages/reset/Reset'))
const ResetConfirm = React.lazy(() => import('./views/pages/reset/ResetConfirm'))
const Verification = React.lazy(() => import('./views/pages/verification/Verification'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // localStorage.removeItem('authTokens')
      // window.location.href = '/login'
      // Error path
      // return (
      //   <div>
      //     <h2>Something went wrong.</h2>
      //     <details style={{ whiteSpace: 'pre-wrap' }}>
      //       {this.state.error && this.state.error.toString()}
      //       <br />
      //       {this.state.errorInfo.componentStack}
      //     </details>
      //   </div>
      //  )
    }
    // Normally, just render children
    return this.props.children
  }
}
const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <ErrorBoundary>
          <AuthProvider>
            <Routes>
              <Route exact path="/" element={<PrivateRoute />}>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Route>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/forgot" name="Forgot Page" element={<Forgot />} />
              <Route exact path="/reset" name="Reset Page" element={<Reset />} />
              <Route exact path="/resetconfirm" name="Reset Page" element={<ResetConfirm />} />
              
              {/* <Route exact path="/verification" name="verification" element={<Verification />} /> */}
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
            </Routes>
          </AuthProvider>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
