import axios from 'axios'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const baseURL = process.env.REACT_APP_API_URL

const useAxios = () => {
  const navigate = useNavigate()
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext)
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  })

  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens.access)
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1
    if (!isExpired) { return req }
    if (!authTokens.refresh) {
      localStorage.removeItem('authTokens')
      navigate('/login')
      return Promise.reject('No refresh token')
    }
    const response = await axios.post(`${baseURL}/token/refresh/`, {
      refresh: authTokens.refresh,
    })

    if (response) {
      const newToken = { ...response.data, refresh: authTokens?.refresh }
      localStorage.setItem('authTokens', JSON.stringify(newToken))
      setAuthTokens(newToken)
      // setUser(jwt_decode(response?.data?.id))
      req.headers.Authorization = `Bearer ${response?.data?.access}`
      return req
    } else {
      localStorage.removeItem('authTokens')
      navigate('/login')
      return Promise.reject('No refresh token')
    }
  })

  axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error?.request?.responseURL === `${baseURL}/token/refresh/`) {
      localStorage.removeItem('authTokens')
      navigate('/login')
      return Promise.reject(error);
    }
    localStorage.removeItem('authTokens')

    switch (error.response.status) {
      case 401:
      case 403:
        localStorage.removeItem('authTokens')
        navigate('/login')
        error.message = "Unauthorized";
        break;
      case 404:
        error.message = "Not Found";
        break;
      case 500:
        error.message = "Internal Server Error";
        break;
      default:
        break;
    }

    return Promise.reject(error);
  })

  return axiosInstance
}

export default useAxios
