import React, { createContext, useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null,
  )
  const [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null))
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const location = useLocation();
  // const loginUser = async (username, password) => {
  //   const response = await fetch('http://34.203.13.91:8000/dash/token/', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       username,
  //       password,
  //     }),
  //   })
  //   const data = await response.json()

  //   if (response.status === 200) {
  //     setAuthTokens(data)
  //     setUser(jwt_decode(data.access))
  //     localStorage.setItem('authTokens', JSON.stringify(data))
  //     navigate('/persist_setup/priorities')
  //   } else {
  //     alert('Something went wrong!')
  //   }
  // }

  const loginUser = async (username, password) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/token/`,

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => {

        if (response.status === 200) {
          setAuthTokens(response.data)
          setUser(jwt_decode(response.data.access))
          localStorage.setItem('authTokens', JSON.stringify(response.data))
          navigate('/persist_setup/institutional_info')
          // navigate('/dashboard')
        } else {
          alert('Something went wrong!')
        }
      }).catch((err) => {
        console.log(err)
        toast.error('Something went wrong!');
      }
      );
  }


  const setLoginUser = (response) => {
    setAuthTokens(response.data)
    setUser(jwt_decode(response.data.access))
    localStorage.setItem('authTokens', JSON.stringify(response.data))
    navigate('/persist_setup/institutional_info')
  }


  const registerUser = async (username, password, password2) => {
    const response = await fetch('${baseURL}/dj-rest-auth/registration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
        password2,
      }),
    })
    if (response.status === 201) {
      navigate('/login')
    } else {
      alert('Something went wrong!')
    }
  }

  const logoutUser = () => {
    setAuthTokens(null)
    setUser(null)
    localStorage.removeItem('authTokens')
    navigate('/login')
  }

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
    setLoginUser
  }

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access))
      if (location.pathname === "/") {
        navigate('/persist_setup/institutional_info')
      }
    }
    setLoading(false)
  }, [authTokens, loading])

  return <AuthContext.Provider value={contextData}>
    <ToastContainer />
    {loading ? null : children}
  </AuthContext.Provider>
}
